.footer {
  width: 100%;
  height: 50px;
  background: #162B3C;
  color: floralwhite;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-family: "Montserrat";
}
