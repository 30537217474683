.playground {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 65vh;
  background: radial-gradient(
    circle,
    rgba(63, 124, 172, 1) 39%,
    rgba(75, 168, 209, 1) 100%
  );
  overflow: hidden;
}

@media screen and (max-width: 428px) {
  .playground {
    overflow: scroll;
  }
}
