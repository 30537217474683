.header {
  width: 100%;
  height: 25%;
  /*background: #162B3C;*/
  color: floralwhite;
  background-image: radial-gradient(
      circle,
      rgba(17, 18, 19, 0.9) 35%,
      rgba(75, 168, 209, 0.3) 120%
    ),
    url("https://images.unsplash.com/photo-1633356122544-f134324a6cee?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80");

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 1.5em;
  font-family: "Montserrat", serif;
}

.text-and-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  margin: 10px 0 15px 0;
}

h2 {
  margin: 0 0 5px 0;
  font-size: 1em;
  color: #e8dbc5;
}

p {
  margin: 0;
  font-size: 0.6em;
  color: #e8dbc5;
}

.profile {
  border-radius: 50%;
  width: 180px;
  height: auto;
  margin: 15px 0;
}

.github-logo {
  margin: 8px 0;
  min-width: 0.8vw;
  height: auto;
}

@media screen and (max-width: 428px) {
  .profile {
    width: 160px;
  }
}

@media screen and (max-width: 414px) {
  .profile {
    width: 150px;
  }
}

@media screen and (max-width: 390px) {
  .profile {
    width: 140px;
  }
}

@media screen and (max-width: 320px) {
  .profile {
    width: 120px;
  }
}
