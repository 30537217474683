.container {
  width: 80%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  font-family: "Montserrat", serif;
}
.by-techno,
.by-type {
  border-radius: 7px;
  margin: 5px;
}

.by-techno {
  border: 1px solid #162b3c;
}

.by-type {
  border: 1px solid #e79823;
}

.title-sort-techno,
.title-sort-type {
  text-align: center;
  margin-top: 3px;
  font-size: 1rem;
  font-weight: bold;
}

.by-type input,
.by-techno input {
  margin: 10px 10px;
  padding: 5px;
  width: 12vw;
  height: 4vh;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.by-techno input {
  background: #162b3c;
  color: #e8dbc5;
}
.by-techno input:hover {
  background: #e79823;
  color: #162b3c;
}

.by-type input {
  background: #e79823;
  color: #162b3c;
}
.by-type input:hover {
  background: #162b3c;
  color: #e8dbc5;
}

@media screen and (max-width: 428px) {
  .container {
    width: 95%;
    align-items: start;
  }
  .by-techno,
  .by-type {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
  }

  .by-type input,
  .by-techno input {
    margin: 10px 10px;
    padding: 5px;
    width: 38vw;
    height: 6vh;
  }
}

@media screen and (max-width: 390px) {
  .by-type input,
  .by-techno input {
    margin: 5px 5px;
    padding: 5px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 320px) {
  .by-type input,
  .by-techno input {
    margin: 5px 5px;
    padding: 5px;
    font-size: 0.85rem;
    width: 42vw;
    height: 8vh;
  }
}
